/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleEatingIceCream from '../images/heroes/couple-eating-icecream.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import {
  Columns,
  Column,
  Hero,
  LinkButton,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import isCallCenterOpen from '../../../../utils/isOpen'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-enroll']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Aetna Medicare',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'nofollow,noindex',
        },
        path: '/enroll',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare',
    defaultRobots: 'nofollow,noindex',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-top"
              image={
                <img
                  src={coupleEatingIceCream}
                  alt="elderly couple eating ice cream"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">Enroll In Medicare </Typography>

                  <Typography variant="h4">
                    Our process is quick, easy, and confidential{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent
          backgroundColor="primary"
          mainContent={
            <>
              <Typography variant="h2" color="light">
                How can we help?
              </Typography>
              <Typography variant="body" color="light">
                TTY: 711, available 24/7
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <LinkButton
                  variant="feature"
                  to="/schedule"
                  color="light"
                  className="margin-x-auto"
                >
                  Schedule a Call
                </LinkButton>
              </Column>
              <Column>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="light"
                  className="margin-x-auto"
                >
                  Order by phone
                </LinkButton>
              </Column>
              <Column>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="light"
                  className="margin-x-auto"
                >
                  Request a quote
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Expert Answers from a Trusted Brand
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-heart.svg"
                    alt="heart icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Eligibility
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/enrollment#am-i-eligible"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    How to Enroll
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/enrollment#how-do-i-enroll"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-cards.svg"
                    alt="cards icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Plans Available
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/plans"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
